<template>
  <div class="basicInfo">
    <div class="basicInfoMain">
      <div class="review-line">
        <div class="review-item">
          <div class="review-item-title font30">{{$t('lang.BText17')}}</div>
          <div class="review-item-main">
            <div>
              {{ shipmentData.basicInfoMap.shipperCompanyName }}
            </div>
            {{ shipmentData.basicInfoMap.shipperAddress1
            }}{{ shipmentData.basicInfoMap.shipperAddress2
            }}<span v-show="shipmentData.basicInfoMap.shipperCity">,</span
            >{{ shipmentData.basicInfoMap.shipperCity
            }}<span v-show="shipmentData.basicInfoMap.shipperCountryName"
              >,</span
            >{{ shipmentData.basicInfoMap.shipperCountryName }}
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title">Notify party</div>
          <div class="review-item-main">
            <div>
              {{ shipmentData.basicInfoMap.notifyCompanyName }}
            </div>
            {{ shipmentData.basicInfoMap.notifyAddress1 }}
            {{ shipmentData.basicInfoMap.notifyAddress2
            }}<span v-show="shipmentData.basicInfoMap.notifyCity">,</span>
            {{ shipmentData.basicInfoMap.notifyCity
            }}{{ shipmentData.basicInfoMap.notifyCity }}
            <span v-show="shipmentData.basicInfoMap.notifyCountryName"
              >,</span
            >
            {{ shipmentData.basicInfoMap.notifyCountryName }}
          </div>
        </div>
      </div>
      <div class="review-line">
        <div class="review-item">
          <div class="review-item-title">{{$t('lang.BText3')}}</div>
          <div class="review-item-main">
            <div>
              {{ shipmentData.basicInfoMap.consigneeCompanyName }}
            </div>
            <div>
              {{ shipmentData.basicInfoMap.consigneeAddress1
              }}{{ shipmentData.basicInfoMap.consigneeAddress2 }}
              <span v-show="shipmentData.basicInfoMap.consigneeCity">,</span>
              {{ shipmentData.basicInfoMap.consigneeCity }}
              <span v-show="shipmentData.basicInfoMap.consigneeCountryName"
                >,</span
              >
              {{ shipmentData.basicInfoMap.consigneeCountryName }}
            </div>
          </div>
        </div>
      </div>
      <div class="review-line">
        <div class="review-item">
          <div class="review-item-title font30">Origin</div>
          <div class="review-item-main">
            <div class="item2">
              <div class="item2-title2">Origin port :</div>
              <div class="item2-content">
                {{ shipmentData.basicInfoMap.originPort }}
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">Cargo ready day:</div>
              <div class="item2-content">
                {{ shipmentData.basicInfoMap.cargoReadyDate }}
              </div>
            </div>
            <div class="item2-title1">Services</div>
            <div class="line-key">
              <el-checkbox
                v-model="shipmentData.basicInfoMap.pickupCheck"
                true-label="1"
                false-label="0"
                disabled
              ></el-checkbox>
              Pick up
            </div>
            <div class="item2-content2">
              {{ shipmentData.basicInfoMap.pickupAddress1 }}
              {{ shipmentData.basicInfoMap.pickupAddress2
              }}<span v-if="shipmentData.basicInfoMap.pickupCity">,</span
              >{{ shipmentData.basicInfoMap.pickupCity
              }}<span v-if="shipmentData.basicInfoMap.pickupCountryName">,</span
              >{{ shipmentData.basicInfoMap.pickupCountryName }}
            </div>
            <div class="line-key">
              <el-checkbox
                v-model="shipmentData.basicInfoMap.customsClearanceCheck"
                true-label="1"
                false-label="0"
                disabled
              ></el-checkbox>
              Customs clearance
            </div>
          </div>
        </div>
        <div class="review-item">
          <div class="review-item-title font30">Destination</div>
          <div class="review-item-main">
            <div class="item2">
              <div class="item2-title2">Discharge port:</div>
              <div class="item2-content">
                {{ shipmentData.basicInfoMap.dischargePort }}
              </div>
            </div>
            <div class="item2">
              <div class="item2-title2">Delivery day:</div>
              <div class="item2-content">
                {{ shipmentData.basicInfoMap.deliveryDate }}
              </div>
            </div>
            <div class="item2-title1">Services</div>
            <div class="line-key">
              <el-checkbox
                v-model="shipmentData.basicInfoMap.deliveryCheck"
                true-label="1"
                false-label="0"
                disabled
              ></el-checkbox>
              Delivery
            </div>
            <div class="item2-content2">
              {{ shipmentData.basicInfoMap.deliveryAddress1 }}
              {{ shipmentData.basicInfoMap.deliveryAddress2
              }}<span v-if="shipmentData.basicInfoMap.deliveryCity">,</span
              >{{ shipmentData.basicInfoMap.deliveryCity
              }}<span v-if="shipmentData.basicInfoMap.deliveryCompanyName"
                >,</span
              >{{ shipmentData.basicInfoMap.deliveryCompanyName }}
            </div>
          </div>
        </div>
      </div>
      <div class="review-item">
        <div class="review-item-title font30">Container</div>
        <div class="review-item-main">{{ shipmentData.container }}</div>
        <div
          class="review-item-main"
          v-show="shipmentData.basicInfoMap.containerShow == 1"
        >
          <div class="item2">
            <div class="item2-title2">{{$t('lang.NText127')}}:</div>
            <div class="item2-content">{{shipmentData.basicInfoMap.hblChassisDays}}</div>
            <!-- <div
              class="item2-content"
              v-show="
                shipmentData.basicInfoMap.hblChassisDays != 0 &&
                shipmentData.basicInfoMap.hblChassisDays != ''
              "
            >
              <span v-if="shipmentData.basicInfoMap.hblChassisDays == 'TARIFF'"
                >not subject TO
                {{ shipmentData.basicInfoMap.hblChassisRate }}</span
              >
              <span v-else-if="shipmentData.basicInfoMap.hblChassisRate"
                >subject TO {{ shipmentData.basicInfoMap.hblChassisRate }} /
                {{ shipmentData.basicInfoMap.hblChassisDayType }}</span
              >
            </div> -->
          </div>
          <div class="item2">
            <div class="item2-title2">{{$t('lang.NText128')}}:</div>
            <div class="item2-content">{{shipmentData.basicInfoMap.hblPerDiemDays}}</div>
            <!-- <div
              class="item2-content"
              v-show="
                shipmentData.basicInfoMap.hblPerDiemDays != 0 &&
                shipmentData.basicInfoMap.hblPerDiemDays != ''
              "
            >
              <span v-if="shipmentData.basicInfoMap.hblPerDiemDays == 'TARIFF'"
                >charge per tariff</span
              >
              <span v-else-if="shipmentData.basicInfoMap.hblPerDiemDays"
                >hblPerDiemRate {{ shipmentData.basicInfoMap.hblPerDiemDays }}/
                {{ shipmentData.basicInfoMap.hblPerDiemDayType }}</span
              >
            </div> -->
          </div>
        </div>
      </div>

      <div class="review-item">
        <div class="review-item-title font30">Cargo</div>
        <div class="review-item-main">
          <div class="item2">
            <div class="item2-title2">Cargo type:</div>
            <div class="item2-content">
              {{ shipmentData.basicInfoMap.cargoType }}
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Commodity:</div>
            <div class="item2-content">
              {{ shipmentData.basicInfoMap.commodity }}
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Total weight:</div>
            <div class="item2-content">
              <div class="number">
                {{ shipmentData.basicInfoMap.totalWeight }}
              </div>
              <div class="unit">
                {{ shipmentData.basicInfoMap.totalWeightCode }}
              </div>
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Total volume:</div>
            <div class="item2-content">
              <div class="number">
                {{ shipmentData.basicInfoMap.totalVolume }}
              </div>
              <div class="unit">
                {{ shipmentData.basicInfoMap.totalVolumeCode }}
              </div>
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Total quantity:</div>
            <div class="item2-content">
              <div class="number">
                {{ shipmentData.basicInfoMap.totalquantity }}
              </div>
              <div class="unit">
                {{ shipmentData.basicInfoMap.totalquantityCode }}
              </div>
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">PO Reference:</div>
            <div class="item2-content">
              {{ shipmentData.basicInfoMap.poReference }}
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Marks:</div>
            <div class="item2-content">
              {{ shipmentData.basicInfoMap.marks }}
            </div>
          </div>
          <div class="item2">
            <div class="item2-title2">Cargo Release Type:</div>
            <div class="item2-content">
              {{ shipmentData.basicInfoMap.cargoReleaseType }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="review-item">
        <div class="review-item-title font30">Remark</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import test from "../../test/test";
export default {
  components: { dwCheckBox },
  data() {
    return {
      bookingStatusList: [
        {
          name: "Pre-booking",
        },
        {
          name: "Pricing",
        },
        {
          name: "Pricing Confirm & Shipping authorization",
        },
        {
          name: "Shipping",
        },
      ],
      viewType: 1,
      listData: test.QuotationData,
      hasQuotation: false,
      showMyTem: false,
    };
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    shipmentData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // shipmentData: function () {
    //   return this.$store.state.api.shipmentData;
    // },
  },
  created() {},
  beforeDestroy() {
    this.$store.commit("api/SET_SHIPMENT", {});
  },
  methods: {
    Edit() {
      this.$emit("editBooking");
    },
    SaveTem() {
      this.showMyTem = true;
    },
    Cancel() {
      this.showMyTem = false;
    },
    Previous() {
      this.$emit("clickPrevious");
    },
    Submit() {},
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.basicInfo {
  .timeline {
    background-color: $background1;
    padding: 20px;
    border: 1px solid $borderColor1;
    .step {
      min-width: 250px !important;
    }
  }

  .basicInfoMain {
    width: 860px;
    height: auto;
    color: $mainColor;
    margin: auto;
    text-align: left;
    font-size: 20px;
    min-height: 600px;
    padding: 40px 0;
  }

  .basicInfoMainTitle {
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid $mainColor;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .Ocean-freight-FCL {
      height: 60px;
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        border: 2px solid $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: $mainColor;
        }
      }
      .icon-line {
        width: 1px;
        height: 30px;
        background: $mainColor;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
      }
    }
    .shippingAuthorization {
      height: 60px;
      width: 200px;
      line-height: 20px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
  }

  .review-item {
    margin-bottom: 40px;
    .review-item-title {
      font-size: 20px;
      border-bottom: 2px solid $mainColor;
      padding: 20px 0;
      margin-bottom: 10px;
    }
    .font30 {
      font-size: 30px;
    }
    .review-main-title2 {
      font-size: 14px;
      color: $fontColor2;
      display: flex;
      padding: 0 10px;
    }
    .user {
      background-color: $background2;
      color: #fff;
      padding: 0 3px;
      margin: 0 5px;
      white-space: nowrap;
      height: 16px;
      font-size: 12px;
      line-height: 16px;
    }
    .review-item-main {
      padding: 0 10px;
    }
    .item2 {
      display: flex;
      padding: 10px 0;
      .item2-title2 {
        color: $fontColor2;
        white-space: nowrap;
        margin-right: 10px;
      }
      .item2-content {
        display: flex;
        .number {
          width: 100px;
          text-align: right;
        }
        .unit {
          color: $background2;
          margin-left: 5px;
        }
      }
    }
    .item2-title1 {
      margin-top: 20px;
      line-height: 30px;
      position: relative;
      margin-bottom: 10px;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #e6e6e6;
      }
    }
    .item2-content2 {
      padding: 10px 0;
    }
    .line-key {
      display: flex;
      line-height: 30px;
      .line-key-icon {
        width: 10px;
        height: 10px;
        background: $mainColor;
        margin-right: 10px;
        margin: 10px 10px 10px 0;
      }
      .el-checkbox {
        margin-right: 10px;
      }
    }
    .item3 {
      .item2-title2 {
        margin-top: 20px;
        line-height: 30px;
        color: $fontColor2;
      }
      .review-main-title2 {
        font-size: 14px;
        padding-left: 30px;
        margin-top: 10px;
      }
      .item2-content {
        padding: 10px 0 20px 30px;
        display: flex;
      }
    }
  }
  .review-line {
    display: flex;
    justify-content: space-between;
    .review-item {
      width: 48%;
    }
    .review-item-title {
      font-size: 30px;
      border-bottom: 2px solid $mainColor;
      padding: 10px;
      margin-bottom: 10px;
    }
  }
  .editButton:focus,
  .editButton {
    height: 64px;
    font-size: 30px;
    border: 2px solid $mainColor;
    color: $mainColor;
    border-radius: 0;
    background-color: #fff;
  }
  .editButton:hover {
    height: 64px;
    font-size: 30px;
    border: 2px solid $background2;
    color: #fff;
    background-color: $background2;
    border-radius: 0;
  }

  .booking-left-menu {
    position: absolute;
    top: 200px;
    left: -44px;
    .button {
      width: 146px;
      height: 60px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      font-size: 20px;
      border: 2px solid $mainColor;
      line-height: 60px;
      background-color: #fff;
      color: $mainColor;
      cursor: pointer;
    }
    .booking-button {
      margin-top: 30px;
    }
    .quotation-button {
      margin-top: 100px;
    }
    .chatHistory-button {
      margin-top: 100px;
    }
    .button:hover,
    .is-check {
      background-color: $mainColor;
      color: $background2;
    }
  }

  .el-upload-dragger {
    width: 860px;
    border: 0;
    height: 492px;
    position: relative;
    background: #fff;
    z-index: 100;
    .el-upload__text {
      font-size: 60px;
      color: $fontColor2;
      line-height: 65px;
      text-align: center;
      margin-top: 100px;
    }
    .button {
      background-color: $mainColor;
      color: #fff;
      width: 400px;
      height: 64px;
      line-height: 64px;
      top: 220px;
      font-size: 20px;
      margin: 80px auto;
    }
  }
}
</style>